.reel {
    display: grid;
    cursor: pointer;
  }

  .chart-container {
    display: flex;
    justify-content: center;
    transform: scale(0.7);
    margin-bottom: 75px;
    margin-top: 35px;
  }
  
  .img-container {
    background-color: transparent;
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    z-index: 9;
    grid-area: 1/1;
    margin-left: 70px;
    margin-top: 72px;
    position: absolute;
  
    img {
      width: auto;
      height: 80%;
      object-fit: contain;
    }
  }
  
  .progress {
    grid-area: 1/1;
    height: 420px;
    width: 390px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 32px;
  
    &.paused {
      .number {
        color: #929291;
      }
  
      .small {
        color: #929291;
      }
  
      .saved-text {
        color: #929291;
      }
    }
  
    .number {
      margin-left: 8px;
    }
  
    .small {
      font-size: 21px;
    }
  }
  
  .number {
    font-family: HaboroContrast-NorReg;
    font-size: 58.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.6px;
    color: #000000;
  }
  
  .saved {
    grid-area: 1/1;
    height: 420px;
    width: 390px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    z-index: 2;
  
    .numer {
      font-size: 70px;
    }
  }
  
  .chart {
    grid-area: 1/1;
    height: 420px !important;
    width: 390px !important;
    position: absolute;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 1px, 0);
  }
  
  .saved-text {
    font-family: 'Gotham-Medium';
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.55;
    letter-spacing: 0.55px;
    position: relative;
  }
  
  .echarts-for-react  {
    position: absolute;
    bottom: 0;
    bottom: 42px;
    width: 100%;
  }

  /* form */

  .form-wrapper p {
    font-family: 'Gotham-Book';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: -0.37px;
    color: #000000;
  }

  .date-by {
    font-family: 'HaboroContrast-NorBoo';
    font-size: 43.2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.39px;
    color: #000000;
    margin-bottom: 25px;
  }

  .date-by span {
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .date-by span::after {
    position: absolute;
    content: '';
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 14px;;
    background-color: #f39a7c;
    z-index: -1;
  }

  .radio-button {
    margin-left: 7px;
    width: 92%;
    margin: auto;
  }

  .text-area-heading {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    color: #000000;
    font-family: 'Gotham-Medium';
    margin-bottom: 5px;
  }

  .btn-submit {
    width: 84%;    
    margin-left: 10px;
  }

  .main-wrapper {
    position: relative;
  }

  .main-wrapper h2 {
    position: absolute;
    top: -20px;
    text-transform: capitalize;
    text-align: right;
    font-size: 56px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.8px;
    text-align: center;
    color: #000000;
    font-family: 'HaboroContrast-NorBoo';
    margin-top: 0;    
  width: 100%;
  right: -210px;
  }

  

  .slider-box {
  height: 3px !important;
  border-radius: 0 !important;
  background-color: #979797 !important;
  }
  .slider-box:nth-child(2) {
    background-color: red;
  }
  .css-18l2o4k-Slider:after {
    height: 23px !important;
    width: 12px !important;
    border: 3px solid #f8e0d7;
    border-radius: 0 !important;
    background-color: #f39b7d !important;
    transform: rotate(20deg);
    top: -11.5px !important;
    box-shadow: none !important;
  }

  .css-19imoqi:after{
    height: 23px !important;
    width: 12px !important;
    border: 3px solid #f8e0d7;
    border-radius: 0 !important;
    background-color: #f39b7d !important;
    transform: rotate(20deg);
    top: -11.5px !important;
    box-shadow: none !important;
  }

  .increase-btn,   .decrease-btn {
    display: none;
  }
.mobile-view-desc {
  display: none;
}

.desktaop-view-box {
  display: block;
}

/*Success Popup*/
.chart-container.success-popup{
    display: flex;
    justify-content: center;
    transform: scale(0.7);
    margin-bottom: 0;
    margin-top: 35px;
    max-height: 300px !important;
    position: relative;
    min-width: auto !important;
    overflow: visible;

    & .reel{
      position: relative;
    }

    & .img-container{
      background-color: transparent;
      height: 200px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 50%;
      z-index: 9;
      grid-area: 1/1;
      margin-left: 0;
      margin-top: 0;
      position: absolute;
      transform: translate(-50%,0%);
      left: 50%;
      top: 0;
    }

    & .echarts-for-react{
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    & .progress {
      grid-area: 1/1;
      height: 310px;
      width: 390px;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;
      margin-top: 32px;
    }
}

.message-wrapper {
  margin-left: 10px;
}

.chip-info-wrapper .heading {
  font-size: 32px;
  line-height: 52px;
  margin-top: 0;
  margin-bottom: 10px;
}

.kIWtaj p {
  font-size: 16px;
  line-height: 21px;
}

@media only screen and (max-device-width: 768px) {
  .main-wrapper{
    position: absolute;
    max-width: 100%;
  }

  .main-wrapper h2{
    right: 0;
    left: 50%;
    transform: translate(-50%, 0px);
    top: 0px;
    text-align: center;
    text-transform: capitalize;
  }

  .fBpaoU{
    margin-top: 70px;
    text-align: center;
    align-items: center;
  }
  .fMQrLD span {
    line-height: 14px;
  }

  .kNqseQ{
    width: 70%;
    padding: 10px 0;
  }

  .css-nu7yic-Slider{
    width: calc(100% - 25px) !important;
  }

  .biMoMy{
    right: 0;
  }

  .ezIODV{
    margin-left: 10px;
  }

  .WMpnG{
    width: 100%;
    padding: 11px 0px;
    max-width: 100%;
  }

  .text-area-heading{
    text-align: left;
  }

  .WMpnG{
    height: 50px;
  }

  .btn-submit {
    width: 87%;
}

.radio-button{
  width: 85%;
}
  .chart-container{
    margin-top: 180px;
  }

  p.desktaop-view-box {
    margin-bottom: 400px;
}
}

@media only screen and (max-device-width: 767px) {

  .img-container img {
    height: 95%;
  }

  .Modal {
    transform: translate(-50%, 0%) !important;
    top:10px !important;
    height: 96vh;
    overflow: scroll;
  }

  .message-wrapper {
    width: 82%;
    margin-top: 10px;
  }
  .main-wrapper h2{
    font-size: 32px;
    word-break: break-word;
  }

  .form-wrapper p{
    font-size: 16px;
  }

  .date-by{
    font-size: 28px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .hMsHhE{
    font-size: 12px;
    margin-bottom: 0;
  }

  .cpKbyd{
    font-size: 32px;
  }

  .ezIODV{
    font-size: 16px; 
  }

  .text-area-heading{
    font-size: 8.8px;
  }

  .WcitD{
    font-size: 12px;
  }
  .chart-container {
    margin-top: 140px;
}

p.desktaop-view-box {
  margin-bottom: 370px;
}
.echarts-for-react{
  min-width: 270px !important;
}
.img-container{
  height: 200px;
  width: 200px;
  margin-top: 0;
  margin-left: 0;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}
.gtxenT{
  max-width: 90%;
  margin: 20px auto 20px auto;
}
.date-by span::after{
  height: 10px;
  bottom: 4px;
}
}
@media only screen and (max-device-width: 560px) {
  p.desktaop-view-box {
    margin-bottom: 340px;
  }
}
@media only screen and (max-device-width: 380px) {
  .chart-container {
    margin-top: 110px;
    min-width: 380px !important;
    overflow: hidden;
    transform: translate(0%, 10px) scale(0.7);
}
}
@media only screen and (max-device-width: 345px) {
  .chart-container {
    transform: translate(-5%, 10px) scale(0.6);
    margin-top: 100px;
  }
  p.desktaop-view-box {
    margin-bottom: 300px;
}
}
//   @media only screen 
//   and (max-device-width: 760px) {

//     .standard-mobile-view {
//        display: block;
//     }
//     .main-wrapper h2 {
//       right: 0;
//       position: inherit;
//       margin-bottom: 10px;
//       top: 0;
//       font-size: 32px;
//     }
//     .echarts-for-react {
//       left: 0;
//     }
//     .form-wrapper {
//       width: 100%;
//       margin-top: 0;
//     }
//     .message-wrapper {
//       width: 100%;
//     }
//     .btn-submit {
//       width: 100%;
//     }
//     .radio-btn {
//       text-align: left;
//       font-size: 16px;
//     }
//     .chart-container {
//       margin-bottom: 0;
//       margin-top: 0;
//     }

//     .mobile-view-desc {
//       display: block;
//       font-size: 16px;
//       line-height: 21px;
//     }
//     .desktaop-view-box {
//       display: none;
//     }
//     .date-by {
//       font-size: 28px;
//       width: 100%;
//       text-align: center;
//     }
//     .want-mobile {
//       font-size: 12pox;
//       display: block;
//       text-align: center;
//       width: 100%;
//       margin: 0;
//     }

//     .slider-amount {
//       font-size: 32px;
//     }
//     .slider-wrapper {
//       width: 80%;
//       margin: 0 auto;
//     }

//     .progress {
//       height: 300px;
//     }
//     .img-container {
//       margin-top: 0;
//       width: 250px;
//       height: 250px;
//     }

//     .text-area-heading {
//       font-size: 8.8px;
//     }
// }
 
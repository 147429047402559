@font-face {
    font-family: Gotham-Medium;
    src: url("./assets/fonts/Gotham/Gotham Medium.ttf");
}

@font-face {
    font-family: Gotham-Bold;
    src: url("./assets/fonts/Gotham/Gotham Bold.ttf");
}

@font-face {
    font-family: Gotham-Book;
    src: url("./assets/fonts/Gotham/Gotham Book.ttf");
}

@font-face {
    font-family: Gotham-BookItalic;
    src: url("./assets/fonts/Gotham/Gotham Book Italic.ttf");
}

@font-face {
    font-family: Gotham-Narrow;
    src: url("./assets/fonts/Gotham/Gotham Narrow Medium.ttf");
}

@font-face {
    font-family: HaboroContrast-NorBoo;
    src: url("./assets/fonts/Haboro/haborocontrastnormbook-webfont.ttf");
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 10%;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    max-width: 400px;
    z-index: 1000;
}

.Modal:focus {
    outline: none;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.StripeElement {
    /* display: block;
    margin: 10px auto 0px auto;
    padding: 12px 14px;
    border-bottom: 1px solid #0a0a0a;
    background-color: rgba(0,0,0,0.01);
    width: 78%; */

    display: block;
    background-color: transparent;
    width: 80%;
    margin: 30px auto;
    position: relative;
    padding: 11px 0px;
    border-bottom: 1px solid #0a0a0a;

}

@media only screen 
  and (max-device-width: 320px) {

    .StripeElement {
        margin: 30px auto;
    }
}